import React from "react";
import authContainerStyles from "./Auth.module.css";
import FooterLogo from '../../components/UI/FooterLogo/FooterLogo';
import MicrosoftAuthentication from '../../components/UI/MicrosoftAuth/MicrosoftAuth'
import CustomCarousel from "../../components/Carousel";
import firstImage from '../../assets/images/MeetPeteLogo.jpg';
import secondImage from '../../assets/images/PeteOnMountain.jpg';
import OktaAuthentication from "../../components/UI/OctaAuth/OctaAuth";

const AuthContainer = ({ children }) => {
  const images = [
  firstImage,secondImage
  ];

  return (
    <div className="container-fluid">
      <div className="row no-gutter">
        
        <div className="col-lg-6 bg-light">
          <div className={`${authContainerStyles.login} d-flex align-items-center pt-5`}>
            <div className="container">
              <div className="row">
                <div className="col-lg-10 col-xl-7 mx-auto">
                  {children}
                  <MicrosoftAuthentication/>
                  <OktaAuthentication/>
                </div>
              <FooterLogo/>
              </div>
            </div>
          </div>
        </div>
        
        <div className={`col-lg-6 d-none d-lg-flex p-0`}>
          <CustomCarousel images={images}/>
        </div> 
      </div>
    </div>
  );
};

export default AuthContainer;
