import AppRoutes from "./components/Menu/AppRoutes";
import Layout from "./components/Layout";
import { AuthProvider } from "./contexts/AuthContext";
import "./App.css";
import "bootstrap/dist/css/bootstrap.min.css";
import { BrowserRouter as Router } from "react-router-dom";
import SharepointCookieBanner from "./components/SharepointCookieBanner/SharepointCookieBanner";
import { CookieConsentProvider } from "./contexts/CookieConsentContext";
import { OktaAuth, toRelativeUrl, urlParamsToObject } from '@okta/okta-auth-js'; 
import oktaConfig from "./oktaConfig";
import { Security } from '@okta/okta-react'; // Import Okta's Security

const App = () => {

  const oktaAuth = new OktaAuth({
    ...oktaConfig,
  }); 
  const restoreOriginalUri = async (_oktaAuth, originalUri) => {
    window.location.replace(toRelativeUrl(originalUri || "/", window.location.origin));
  };

  return (
    <AuthProvider>
      <CookieConsentProvider>
        <Security oktaAuth={oktaAuth} restoreOriginalUri={restoreOriginalUri}>
          <Router>
            <Layout>
              <SharepointCookieBanner />
              <AppRoutes />
            </Layout>
          </Router>
        </Security>
  
      </CookieConsentProvider>
    </AuthProvider>
  );
};

export default App;
