import Confluence from "../../modules/Confluence/index";
import Dashboard from "../../modules/Dashboard/Dashboard";
import NewDocument from "../../modules/NewDocument/index";
import OrganizationSummary from "../../modules/OrganizationSummary";
import Profile from "../../modules/Profile/index";
import Contact from "../../modules/Contact/index";
import ForgotPassword from "../ForgotPassword";
import Login from "../Login";
import ResetPassword from "../ResetPassword";
import HelpPage from "../../modules/Help";
import { PrivacyNotice } from "../../modules/PrivacyNotice";
import { HowPeteWorks } from "../../modules/HowPeteWorks";
import { HowToSetup } from "../../modules/HowToSetup";
import Users from "../../modules/Users";
import Sharepoint from "../../modules/Sharepoint";
import { HowToSetupUsingSharepoint } from "../../modules/HowToSetupUsingSharepoint";
import PrivacyNoticeVersionHistory from "../PrivacyNoticeContent/sections/PrivacyNoticeVersionHistorySection";
import PrivacyNoticeVersionHistoryPage from "../../modules/PrivacyNoticeVersionHistoryPage";
import { LoginCallback } from "@okta/okta-react";

const routes = [
  {
    path: "/",
    component: <Login />,
    isProtected: false,
  },
  {
    path: "/new",
    component: <NewDocument />,
    isProtected: true,
  },
  {
    path: "/dashboard",
    component: <Dashboard />,
    isProtected: true,
  },
  {
    path: "/confluence",
    component: <Confluence />,
    isProtected: true,
  },
  {
    path: "/summary",
    component: <OrganizationSummary />,
    isProtected: true,
  },
  {
    path: "/profile",
    component: <Profile />,
    isProtected: true,
  },
  {
    path: "/users",
    component: <Users />,
    isProtected: true,
  },
  {
    path: "/contact",
    component: <Contact />,
    isProtected: true,
  },
  {
    path: "/help",
    component: <HelpPage />,
    isProtected: true,
  },
  {
    path: "/login",
    component: <Login />,
    isProtected: false,
  },
  {
    path: "/forgotpass",
    component: <ForgotPassword />,
    isProtected: false,
  },
  {
    path: "/resetpass",
    component: <ResetPassword />,
    isProtected: false,
  },
  {
    path: "/privacy-notice-history",
    component: <PrivacyNoticeVersionHistoryPage />,
    isProtected: false,
  },
  {
    path: "/privacy-notice",
    component: <PrivacyNotice />,
    isProtected: false,
  },
  {
    path: "/how-pete-works",
    component: <HowPeteWorks />,
    isProtected: false,
  },
  {
    path: "/how-to-setup",
    component: <HowToSetup />,
    isProtected: false,
  },
  {
    path: "/how-to-setup-using-sharepoint",
    component: <HowToSetupUsingSharepoint />,
    isProtected: false,
  },
  {
    path: "/sharepoint",
    component: <Sharepoint />,
    isProtected: true,
  },
  {
    path: "/login/callback",
    component: <LoginCallback />,
    isProtected: false,
  },

];

export default routes;
