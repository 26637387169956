import React, { useEffect, useState } from "react";
import { useOktaAuth } from '@okta/okta-react';
import { toast } from "react-toastify";
import { Button, Spinner } from "react-bootstrap";
import { Cookies } from "react-cookie";
import styles from "../../../components/UI/MicrosoftAuth/microsoftAuth.module.css";
import oktaLogo from "../../../assets/images/okta.svg";

const OktaAuthentication = () => {
  const cookies = new Cookies();
  const { oktaAuth } = useOktaAuth();

  const handleLoginWithOkta = async () => {
    await oktaAuth.signInWithRedirect();
    cookies.set('typeOfLogin', 'okta')
  };


  return (
    <Button
      type="button"
      className={`btn ${styles.btnOkta}`}
      onClick={handleLoginWithOkta}
  >
    <span className={styles.oktaLogo}>
      <img src={oktaLogo} alt="Okta Logo" width={'32px'}/>
    </span>
      Log in with OKTA
    </Button>

  );
};

export default OktaAuthentication;
