import React, { useState, useRef, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { ToastContainer } from "react-toastify";
import Button from "../../components/Button";
import Modal from "../../components/Modal";
import { useTargetSystemContext } from "../../contexts/TargetSystemContext";
import ConfluenceMainSection from "./ConfluenceMainSection";
import SharepointMainSection from "./SharepointMainSection.js";
import PDFStep from "../../components/PDFStep/PDFStep";
import ErrorModal from "./ErrorModal.js";
import ChangeShortPasswordModal from "../../components/ChangeShortPasswordModal.js";
import { useOktaAuth } from "@okta/okta-react";
import { Spinner } from "react-bootstrap";

const NewDocument = () => {
  const confluenceMainSectionRef = useRef(null);
  const pdfMainSectionRef = useRef(null);
  const sharepointMainSectionRef = useRef(null);
  const { targetSystem } = useTargetSystemContext();

  const [showModal, setShowModal] = useState(false);
  const [getExecutionIdFromResponse, setGetExecutionIdFromResponse] =
    useState("");

  const [errorModalMessage, setErrorModalMessage] = useState("");
  const [errorModalAssets, setErrorModalAssets] = useState();
  const [isErrorModalOpen, setIsErrorModalOpen] = useState(false);
  const [changeShortPassword, setChangeShortPassword] = useState(
    localStorage.getItem("shortPassword")
  );

  const onNewDocumentClick = () => {
    switch (targetSystem.toLowerCase()) {
      case "pdf": {
        pdfMainSectionRef.current.clearFields();
        break;
      }
      case "confluence": {
        confluenceMainSectionRef.current.clearFields();
        break;
      }
      case "sharepoint": {
        sharepointMainSectionRef.current.clearFields();
        break;
      }
      default: {
        break;
      }
    }
  };

  const navigate = useNavigate();

  const modalButtons = (
    <div className="d-flex justify-content-between align-items-end w-100">
      <Button
        id="dashboard"
        onClick={() =>
          navigate("/dashboard", {
            state: { executionID: getExecutionIdFromResponse },
          })
        }
      >
        Dashboard
      </Button>
      <Button id="create-new-documentation" onClick={onNewDocumentClick}>
        New Document
      </Button>
    </div>
  );

  const handleErrorModalClose = () => {
    setIsErrorModalOpen(false);
    setErrorModalMessage("");
    setErrorModalAssets();

    switch (targetSystem.toLowerCase()) {
      case "pdf": {
        pdfMainSectionRef.current.handleModalClose();
        break;
      }
      case "confluence": {
        confluenceMainSectionRef.current.handleModalClose();
        break;
      }
      default: {
        break;
      }
    }
  };

  const handleErrorModalConfirm = () => {
    switch (targetSystem.toLowerCase()) {
      case "pdf": {
        pdfMainSectionRef.current.confirmCreate();
        break;
      }
      case "confluence": {
        confluenceMainSectionRef.current.confirmCreate();
        break;
      }
      default: {
        break;
      }
    }

    handleErrorModalClose();
  };
  const { authState, oktaAuth } = useOktaAuth();
  const [data, setData] = useState(null);
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    const fetchData = async () => {
      const token = authState?.accessToken?.accessToken;
      try {
        setLoading(true);
        const response = await fetch(
          '/api/config?authorizationVendor=OKTA&organizationName=IWconnect-okta',
          {
            method: 'GET',
            headers: {
              Authorization: `Bearer OKTA_${token}`,
              'Content-Type': 'application/json',
            },
          }
        );
      if (!token) {
        console.log('No access token found. Please log in again.');
        return;
      }
      localStorage.setItem("authMethod",'OKTA');
      // setIsAuthenticated('true')

        setData(response);
      } catch (err) {
        console.error('API call failed:', err);
      }
    };
    setLoading(false)
    fetchData();
  }, []);

  if(loading) {
    return (
      <div className="d-flex justify-content-center align-items-center h-100">
        <Spinner animation="border" role="status"/>  
      </div>

    )
  }
  return (
    <>
      {targetSystem.toLowerCase() === "pdf" ? (
        <PDFStep
          targetSystem={targetSystem}
          setShowModal={setShowModal}
          setGetExecutionIdFromResponse={setGetExecutionIdFromResponse}
          setErrorModalMessage={setErrorModalMessage}
          setErrorModalAssets={setErrorModalAssets}
          setIsErrorModalOpen={setIsErrorModalOpen}
          ref={pdfMainSectionRef}
        />
      ) : (
        <></>
      )}

      {targetSystem.toLowerCase() === "confluence" ? (
        <ConfluenceMainSection
          targetSystem={targetSystem}
          setShowModal={setShowModal}
          setGetExecutionIdFromResponse={setGetExecutionIdFromResponse}
          setErrorModalMessage={setErrorModalMessage}
          setErrorModalAssets={setErrorModalAssets}
          setIsErrorModalOpen={setIsErrorModalOpen}
          ref={confluenceMainSectionRef}
        />
      ) : (
        <></>
      )}

      {targetSystem.toLowerCase() === "sharepoint" ? (
        <SharepointMainSection
          targetSystem={targetSystem}
          setShowModal={setShowModal}
          setGetExecutionIdFromResponse={setGetExecutionIdFromResponse}
          setErrorModalMessage={setErrorModalMessage}
          setErrorModalAssets={setErrorModalAssets}
          setIsErrorModalOpen={setIsErrorModalOpen}
          ref={sharepointMainSectionRef}
        />
      ) : (
        <></>
      )}

      <Modal
        id="create-document-modal"
        onHide={() => setShowModal(false)}
        show={showModal}
        title="Document Creation Started"
        modalButtons={modalButtons}
        backdrop="static"
      >
        <div>
          Document creation is in progress. Please see the status in the
          dashboard screen or create new documentation.
        </div>
      </Modal>
      {changeShortPassword === "true" && (
        <ChangeShortPasswordModal
          show={changeShortPassword}
          setShow={setChangeShortPassword}
          closeButton={false}
          showNewPolicyText={true}
        />
      )}

      <ErrorModal
        isOpen={isErrorModalOpen}
        modalMessage={errorModalMessage}
        modalAssets={errorModalAssets}
        onClose={handleErrorModalClose}
        onConfirm={handleErrorModalConfirm}
      />

      <ToastContainer
        position="top-right"
        limit={1}
        autoClose={5000}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
      />
    </>
  );
};

export default NewDocument;
