import React, { useEffect } from "react";
import { Route, Routes, Navigate, useNavigate } from "react-router-dom";
import { IdleTimerProvider } from "react-idle-timer";
import Cookies from "universal-cookie";
import routes from "./routes";
import { logoutAPI } from "../../service/auth.service";
import { useUserRoleContext } from "../../contexts/UserRoleContext";
import { useAuth } from "../../contexts/AuthContext";
import { useOktaAuth } from "@okta/okta-react";

const cookies = new Cookies();

const AppRoutes = () => {
  const navigate = useNavigate();
  const { userRole } = useUserRoleContext();
  const expiresIn = cookies.get("expiresIn") || "3600";
  const { isAuthenticated } = useAuth();
  const { authState, oktaAuth } = useOktaAuth();
  const typeOfLogin = cookies.get("typeOfLogin");

  const onIdle = () => {
    const authMethod = localStorage.getItem("authMethod")
    if (authMethod !== 'OAUTH2') {
      logoutAPI();
      navigate("/login");
    }
  };

  const isUserAuthenticated = () => {
    const authMethod = localStorage.getItem("authMethod");
    if (authMethod === 'BASIC') {
      const user = cookies.get("user");
      sessionStorage.setItem('userAuthenticated', Boolean(user?.token).toString());
      return Boolean(user?.token).toString()
    } else {
      if (typeOfLogin === 'okta' && authState && !authState.isPending) {
        sessionStorage.setItem('userAuthenticated', authState.isAuthenticated.toString())
        return authState.isAuthenticated.toString();
      }
      if (typeOfLogin === 'microsoft') {
        sessionStorage.setItem('userAuthenticated', isAuthenticated)
        return isAuthenticated;
      }
    }
  };
  const userAuthenticated = isUserAuthenticated();

  return (
    <IdleTimerProvider timeout={+expiresIn * 60} onIdle={onIdle}>
      <Routes>
        {routes.map(({ component, path, isProtected }) => {
          return (
            <Route
              key={path}
              path={path}
              element={
                isProtected && userAuthenticated === 'false' ? (
                  <Navigate to={"/login"} />
                ) : userAuthenticated === 'true' && (path === "/" || path === "/login") ?
                  typeOfLogin === 'okta' && authState?.isAuthenticated?.toString() === 'true' && (path === "/" || path === "/login") ? <Navigate to="/new" /> : (
                    <Navigate to={"/new"} />
                  ) : userAuthenticated === 'true' && userRole === 1 && path === "/users" ? (
                    <Navigate to={"/new"} />
                  )
                    : (
                      component
                    )
              }
            />
          );
        })}
      </Routes>
    </IdleTimerProvider>
  );
};

export default AppRoutes;
